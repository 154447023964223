<template>
    <v-container fluid class="d-flex justify-center align-center" id="voucher_container">
        <voucherCard v-if="dataLoaded" :description="description" :instructions="instructions"/>
    </v-container>
</template>
<script>
import voucherCard from './voucherCard.vue';
import { mapMutations } from 'vuex/dist/vuex.common.js';

export default {
    components: {
        voucherCard
    },
    data() {
        return {
            order: '',
            description: '',
            instructions: {},
            dataLoaded: false
        }
    },
    methods: {
        ...mapMutations("loader", ["loading", "loaded"]),
        async getPaymentIntentInfo() {
            this.loading()
            try {
                let { data: { paymentIntent: { next_action = {}, description = '', amount = 0 } }, status } = await this.axios({
                    method: 'POST',
                    url: '/payments/bank-transfer-reference',
                    data: {
                        orderId: this.order
                    }
                })
                if (status) {
                    this.description = description;
                    this.instructions = next_action ? next_action.display_bank_transfer_instructions : {} ;
                    if (!next_action) {
                        this.instructions.amount_remaining = amount
                    }
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.loaded()
            }
        }
    },
    async created() {
        this.order = this.$route.params.orderId
        await this.getPaymentIntentInfo();
        this.dataLoaded = true;
    }
}
</script>
<style scoped>
#voucher_container {
    background-color: #228b22;
    height: 100vh
}
</style>